import { DocumentApi } from '@blank/api'
import { RoundedElevatedCard } from 'modules/common/components/RoundedElevatedCard/RoundedElevatedCard'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { NewDocumentButton } from '../NewDocumentButton/NewDocumentButton'
import { DocumentList } from './DocumentList/DocumentList'

interface Props {
  className?: string
  documents: DocumentApi[]
}

export const DocumentListCard = ({ className, documents }: Props) => {
  const { t } = useTranslation('invoicing-components')

  return (
    <RoundedElevatedCard className={className}>
      <div className="flex items-center justify-between gap-2">
        <p className="text-lg font-bold">{t('documentListCard.title')}</p>
        <NewDocumentButton variant="tertiary" className="p-0" />
      </div>
      <DocumentList documentList={documents} />
    </RoundedElevatedCard>
  )
}
