import { useAuthenticatedUserCorporateQuery } from 'modules/auth/queries/useAuthenticatedUserCorporateQuery'
import { useCurrentCard } from 'modules/bank/hooks/card/useCurrentCard'
import { useCallback } from 'react'
import { useAuthenticatedUserQuery } from '../../../../auth/queries/useAuthenticatedUserQuery'
import { useCorporateOfferQuery } from '../../../../corporate/queries/useCorporateOfferQuery'

export const useBankCardContainerQueries = () => {
  const { data: user } = useAuthenticatedUserQuery()

  const {
    data: { currentCard, internalAccount },
    isError: isCardError,
    isLoading: isCardLoading,
    refetch: refetchCard,
  } = useCurrentCard()

  const {
    data: { corporate },
    isError: isCorporateError,
    isLoading: isCorporateLoading,
    errorRefetch: refetchCorporate,
  } = useAuthenticatedUserCorporateQuery()

  const {
    data: offer,
    isError: isOfferError,
    isLoading: isOfferLoading,
    refetch: refetchOffer,
  } = useCorporateOfferQuery(user?.corporateId)

  const refetch = useCallback(() => {
    if (isCardError) {
      refetchCard()
    }
    if (isCorporateError) {
      refetchCorporate()
    }
    if (isOfferError) {
      refetchOffer()
    }
  }, [
    isCardError,
    isCorporateError,
    isOfferError,
    refetchCard,
    refetchCorporate,
    refetchOffer,
  ])

  return {
    data: {
      corporate,
      currentCard,
      offer,
      internalAccount,
    },
    isError: isCardError || isCorporateError || isOfferError,
    isLoading: isCardLoading || isCorporateLoading || isOfferLoading,
    refetch,
  }
}
