import { EnumAccountSource } from '@blank/api'
import { useAccountsQuery } from 'modules/bank/queries/useAccountsQuery'
import { useCorporateLifecycleQueries } from 'modules/corporate/hooks/useCorporateLifecycleQueries'
import { useCheckTDAEServiceSubscription } from 'modules/services/hooks/tdae/useCheckTDAEServiceSubscription'

export const useTDAEDiscoveryBannerQueries = () => {
  const {
    data: { lifecycle },
    isSuccess: isCorporateLifecycleSuccess,
  } = useCorporateLifecycleQueries()

  const { data: accounts, isSuccess: isAccountsSuccess } = useAccountsQuery()

  const {
    canAccessTdaeUrssafService,
    isTDAEServiceNeverSubscribed,
    isCheckSuccess,
  } = useCheckTDAEServiceSubscription()

  return {
    data: {
      canAccessTdaeUrssafService,
      isTDAEServiceNeverSubscribed,
      hasInternalAccount: accounts?.data?.some(
        (account) => account.source === EnumAccountSource.INTERNAL
      ),
      hasExternalAccount: accounts?.data?.some(
        (account) => account.source === EnumAccountSource.EXTERNAL
      ),
      lifecycleTDAEServiceStatus: lifecycle?.services?.tdae,
    },
    isSuccess:
      isCorporateLifecycleSuccess && isAccountsSuccess && isCheckSuccess,
  }
}
