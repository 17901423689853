import { CorporateTDAEInformationApi } from '@blank/api'
import { AxiosError } from 'axios'
import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import { QueryOptions } from 'modules/auth/types/queries'
import { ApiError } from 'modules/common/types/error'
import { fetchCorporateTDAEInformation } from '../api/fetchCorporateTDAEInformation'
import { EnumServicesQueryKeys } from './keys'

export const useCorporateTDAEInformationQuery = (
  corporateId?: string,
  queryOptions?: QueryOptions<CorporateTDAEInformationApi, AxiosError<ApiError>>
) => {
  return useQueryWithAuth<CorporateTDAEInformationApi, AxiosError<ApiError>>({
    queryKey: [EnumServicesQueryKeys.CORPORATE_TDAE_INFORMATION, corporateId],
    queryFn: async (_context, accessToken) => {
      if (!corporateId) {
        throw new Error(
          'useCorporateTDAEInformationQuery: Missing parameter corporateId'
        )
      }

      return fetchCorporateTDAEInformation({
        accessToken,
        corporateId,
      })
    },
    enabled: !!corporateId,
    ...queryOptions,
  })
}
