import { ErrorReloadSmall } from 'modules/common/components/ErrorReloadSmall/ErrorReloadSmall'

interface Props {
  onReload: () => void
}

export const InternalAccountContainerErrorReload = ({ onReload }: Props) => {
  return (
    <div className="flex justify-between rounded-2xl border border-info-200 bg-white py-12 shadow-md">
      <ErrorReloadSmall onReload={onReload} />
    </div>
  )
}
