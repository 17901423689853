import { RoundedElevatedCard } from 'modules/common/components/RoundedElevatedCard/RoundedElevatedCard'
import { Skeleton } from 'modules/common/components/_ui/Skeletons/Skeleton/Skeleton'
import { TransactionListSkeleton } from './TransactionList/TransactionListSkeleton'

export const TransactionListCardSkeleton = () => (
  <RoundedElevatedCard>
    <div className="flex items-center justify-between gap-2">
      <div className="flex items-center gap-2">
        <Skeleton className="h-6 w-48" />
        <Skeleton className="h-6 w-6 rounded-lg" />
      </div>
      <div className="flex items-center gap-4">
        <Skeleton className="h-6 w-6 rounded-lg" />
        <Skeleton className="h-10 w-24 rounded-lg" />
      </div>
    </div>
    <TransactionListSkeleton />
  </RoundedElevatedCard>
)
