import { AccountApi } from '@blank/api'
import { friendlyFormatIBAN } from 'ibantools'
import { useTransferMethodFormModal } from 'modules/bank/modals/transfer/TransferMethodFormModal/useTransferMethodFormModal'
import { CopyText } from 'modules/common/components/CopyText/CopyText'
import { RoundedElevatedCard } from 'modules/common/components/RoundedElevatedCard/RoundedElevatedCard'
import { IconInternalAccount } from 'modules/common/components/_icons/IconInternalAccount/IconInternalAccount'
import { IconPlusRounded } from 'modules/common/components/_icons/icons'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { BalanceAmount } from '../BalanceAmount/BalanceAmount'

export interface InternalAccountInformationCardProps {
  account: AccountApi
}

export const InternalAccountInformationCard = ({
  account: { iban, balance, isBlockedInvest },
}: InternalAccountInformationCardProps) => {
  const { t } = useTranslation('bank-components')

  const { showModal: showTransferMethodModal } = useTransferMethodFormModal()

  return (
    <RoundedElevatedCard>
      <div className="flex space-x-2 pb-4">
        <IconInternalAccount className="shrink-0" />
        <div>
          <h3 className="pb-4">{t('internalAccountInformationCard.title')}</h3>
          <p className="text-info-400">
            {t('internalAccountInformationCard.ibanLabel')}
          </p>
          <div className="flex space-x-3">
            <p className="font-bold text-info-400">
              {friendlyFormatIBAN(iban)}
            </p>
            <CopyText textToCopy={iban ?? ''} />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between pb-3 pt-7">
        <Button
          variant="tertiary"
          className="!p-0"
          color="default"
          icon={<IconPlusRounded />}
          onClick={showTransferMethodModal}
        >
          {t('common.newTransfer')}
        </Button>
        {isBlockedInvest ? (
          <p className="font-amount text-2xl font-bold">***** €</p>
        ) : (
          <BalanceAmount amount={parseFloat(balance ?? '0')} />
        )}
      </div>
    </RoundedElevatedCard>
  )
}
