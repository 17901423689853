import { Illustration } from 'modules/common/components/Illustrations/Illustration'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import React from 'react'

export const IncreaseTransferLimitsRequestSuccessModalContent = () => {
  const { t } = useTranslation('bank-components')

  return (
    <div className="space-y-4">
      <Illustration name="check" size="l" />
      <h2>{t('increaseTransferLimitsRequestSuccessModal.title')}</h2>
      <p>{t('increaseTransferLimitsRequestSuccessModal.text')}</p>
    </div>
  )
}
