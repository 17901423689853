import { CorporateLifecycleApi, EnumLifecycleNextAction } from '@blank/api'
import { getIsKycStarted } from 'modules/auth/lib/utils/getIsKycStarted'
import { Image } from 'modules/common/components/Image/Image'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { EnumRoutes } from 'modules/common/routes'
import { Corporate } from 'modules/corporate/types/corporates'
import { Trans } from 'next-i18next'
import { PendingSponsorshipMessage } from '../../../corporate/components/PendingSponsorshipMessage/PendingSponsorshipMessage'
import { AccountOpeningCardProgressBar } from './InternalAccountOpeningCardProgressBar/AccountOpeningCardProgressBar'

export interface AccountOpeningCardProps {
  lifecycle: CorporateLifecycleApi
  corporate: Corporate
}

export const InternalAccountOpeningCard = ({
  lifecycle,
  corporate,
}: AccountOpeningCardProps) => {
  const { t } = useTranslation('home-components')

  const isKycStarted = getIsKycStarted({ corporate })
  const isOnboardingRefused =
    lifecycle.nextAction === EnumLifecycleNextAction.ONBOARDING_REFUSED

  const context = isKycStarted ? lifecycle.nextAction?.toUpperCase() : null

  return (
    <div className="relative flex justify-between overflow-hidden rounded-2xl border border-info-200 bg-white shadow-md">
      <div className="absolute h-full w-[6px] bg-primary" />
      <div className="flex flex-col gap-4 p-10">
        <h2>{t('accountOpeningCard.title', { context })}</h2>
        <Trans parent="p" className="whitespace-pre-line text-lg">
          {t('accountOpeningCard.description', { context })}
        </Trans>
        <AccountOpeningCardProgressBar
          lifecycle={lifecycle}
          corporate={corporate}
        />
        <PendingSponsorshipMessage
          isOnboardingRefused={isOnboardingRefused}
          corporate={corporate}
          type="important"
        />

        <Button
          href={isKycStarted ? EnumRoutes.KYC_ROOT : EnumRoutes.OFFER_SELECTION}
          variant={isOnboardingRefused ? 'secondary' : 'primary'}
          className="max-w-fit"
          color="default"
          data-testid="homepage-open-account-button"
        >
          {t('accountOpeningCard.ctaLabel', { context })}
        </Button>
      </div>
      <div className="relative w-[450px] flex-shrink-0 self-stretch">
        <Image
          alt="cards-img"
          src="/assets/img/home-page-both-cards.png"
          fill
          className="object-cover"
        />
      </div>
    </div>
  )
}
