import { IconInformationSquare } from 'modules/common/components/_icons/icons'
import { Tooltip } from 'modules/common/components/_ui/Tooltip/Tooltip'
import { useTooltip } from 'modules/common/hooks/useTooltip'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { Trans } from 'next-i18next'

export const TransactionListTooltip = () => {
  const { t } = useTranslation('bank-components')
  const { registerTooltip, registerTooltipTrigger } = useTooltip({
    placement: 'bottom-center',
  })

  return (
    <>
      <span {...registerTooltipTrigger()}>
        <IconInformationSquare />
      </span>
      <Tooltip className="w-72" color="primary" {...registerTooltip()}>
        <Trans parent="p" className="text-sm">
          {t('transactionListCard.tooltip')}
        </Trans>
      </Tooltip>
    </>
  )
}
