import { LimitParametersRuleApi } from '@blank/api'
import { mergeClasses } from '@blank/utilities'
import { Amount } from 'modules/common/components/_ui/Amount/Amount'
import { useTranslation } from 'modules/common/hooks/useTranslation'

interface Props {
  className?: string
  dailyLimit: LimitParametersRuleApi
  monthlyLimit: LimitParametersRuleApi
}

export const BankingLimits = ({
  className,
  dailyLimit,
  monthlyLimit,
}: Props) => {
  const { t } = useTranslation('bank-components')

  return (
    <div className={mergeClasses('w-full divide-y divide-info-100', className)}>
      <div className="flex flex-col gap-y-2 pb-4">
        <p className="font-bold text-info-400">
          {t('bankingLimit.title', { context: 'daily' })}
        </p>
        <Amount amount={dailyLimit.value as number} />
      </div>
      <div className="flex flex-col gap-y-2 pt-4">
        <p className="font-bold text-info-400">
          {t('bankingLimit.title', { context: 'monthly' })}
        </p>
        <Amount amount={monthlyLimit.value as number} />
      </div>
    </div>
  )
}
