import { mergeClasses } from '@blank/utilities'

export type Fill = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
export const COLORS = ['primary', 'success', 'danger', 'warning'] as const
export const PROGRESS_BAR_MAX = 12

interface Props {
  fill: Fill
  color: (typeof COLORS)[number]
}

export const ProgressBar = ({ fill, color }: Props) => {
  return (
    <div className="h-2 w-full rounded bg-info-100">
      <div
        className={mergeClasses('h-full rounded', {
          // We need to explicit all the potential classes to prevent purge
          // https://github.com/nuxt-community/tailwindcss-module/issues/55
          'bg-success': color === 'success',
          'bg-danger': color === 'danger',
          'bg-primary': color === 'primary',
          'bg-warning': color === 'warning',
          'w-0': fill === 0,
          'w-1/12': fill === 1,
          'w-2/12': fill === 2,
          'w-3/12': fill === 3,
          'w-4/12': fill === 4,
          'w-5/12': fill === 5,
          'w-6/12': fill === 6,
          'w-7/12': fill === 7,
          'w-8/12': fill === 8,
          'w-9/12': fill === 9,
          'w-10/12': fill === 10,
          'w-11/12': fill === 11,
          'w-12/12': fill === 12,
        })}
      ></div>
    </div>
  )
}
