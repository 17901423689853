import { Skeleton } from 'modules/common/components/_ui/Skeletons/Skeleton/Skeleton'

export const DocumentListSkeleton = () => {
  const itemKeys = Array.from(Array(5).keys())

  return (
    <div className="mx-4 mt-3">
      <table className="w-full">
        <tbody className="divide-y divide-info-100">
          {itemKeys.map((key) => {
            return (
              <tr key={key} className="w-full">
                <td className="w-1/2 space-y-2 py-1 font-bold">
                  <Skeleton className="h-5 w-full max-w-[80px]" />
                  <Skeleton className="h-5 w-full max-w-[120px]" />
                </td>
                <td className="w-1/4 py-1">
                  <Skeleton className="h-6 w-20" />
                </td>
                <td className="w-1/4 py-1">
                  <span className="flex justify-end">
                    <Skeleton className="h-6 w-28" />
                  </span>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
