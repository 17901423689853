import { AccountApi, EnumAccountSource } from '@blank/api'
import { useExportExternalAccountTransactionsModal } from 'modules/bank/modals/transaction/useExportExternalAccountTransactionsModal'
import { useExportInternalAccountTransactionsModal } from 'modules/bank/modals/transaction/useExportInternalAccountTransactionsModal'
import { IconDownloadArrow } from 'modules/common/components/_icons/icons'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { useCallback } from 'react'

interface Props {
  account: AccountApi
  disabled?: boolean
}

export const AccountTransactionsExportButton = ({
  account,
  disabled,
}: Props) => {
  const { showModal: showExportInternalAccountTransactionsModal } =
    useExportInternalAccountTransactionsModal(account)

  const { showModal: showExportExternalAccountTransactionsModal } =
    useExportExternalAccountTransactionsModal(account)

  const showExportAccountTransactionsModal = useCallback(
    () =>
      account.source === EnumAccountSource.INTERNAL
        ? showExportInternalAccountTransactionsModal()
        : showExportExternalAccountTransactionsModal(),
    [
      account.source,
      showExportExternalAccountTransactionsModal,
      showExportInternalAccountTransactionsModal,
    ]
  )

  return (
    <Button
      color="default"
      variant="none"
      icon={<IconDownloadArrow />}
      onClick={showExportAccountTransactionsModal}
      disabled={disabled}
    />
  )
}
