import {
  ErrorReloadSmall,
  ErrorReloadSmallProps,
} from 'modules/common/components/ErrorReloadSmall/ErrorReloadSmall'
import { RoundedElevatedCard } from 'modules/common/components/RoundedElevatedCard/RoundedElevatedCard'

export const DocumentListCardError = (props: ErrorReloadSmallProps) => (
  <RoundedElevatedCard>
    <ErrorReloadSmall {...props} withIcon={false} className="px-6 py-16" />
  </RoundedElevatedCard>
)
