import { mergeClasses } from '@blank/utilities'
import { forwardRef } from 'react'
import { FieldError } from 'react-hook-form'
import { HelperText } from '../../HelperText/HelperText'
import { useRadioButtonTheme } from './useRadioButtonTheme'

export const RADIO_BUTTON_VARIANTS = ['default', 'outline'] as const
export type RadioButtonVariant = (typeof RADIO_BUTTON_VARIANTS)[number]

interface Props extends Omit<React.HTMLProps<HTMLInputElement>, 'label'> {
  id: string
  icon?: JSX.Element
  primaryLabel: string | React.ReactNode
  primaryLabelClassName?: string
  secondaryLabel?: string
  error?: FieldError
  dataTestId?: string
  variant?: RadioButtonVariant
}

export const RadioButton = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      icon,
      primaryLabel,
      error,
      className,
      primaryLabelClassName,
      secondaryLabel,
      dataTestId,
      variant = 'default',
      ...inputProps
    },
    ref
  ) => {
    const { inputClassName, labelClassName, secondaryLabelClassName } =
      useRadioButtonTheme({
        variant,
        disabled: inputProps.disabled,
        hasSecondaryLabel: !!secondaryLabel,
        withIcon: !!icon,
      })

    return (
      <div
        className={mergeClasses(
          'relative',
          { 'mt-2 first:mt-0': variant === 'outline' },
          className
        )}
        data-testid={dataTestId}
      >
        <input
          {...inputProps}
          ref={ref}
          id={id}
          type="radio"
          className={mergeClasses(inputClassName, { hidden: icon })}
        />
        <label htmlFor={id} className={labelClassName}>
          {icon}
          <div className="flex flex-col">
            <span className={primaryLabelClassName}>{primaryLabel}</span>
            {secondaryLabel && (
              <span className={secondaryLabelClassName}>{secondaryLabel}</span>
            )}
          </div>
          {!!error?.message && (
            <HelperText color="danger" text={error.message} />
          )}
        </label>
      </div>
    )
  }
)
