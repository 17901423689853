import { EnumMimeTypes } from '@blank/api'
import { getFileUrl } from 'modules/common/lib/helpers/getFileUrl/getFileUrl'

interface DownloadFileFromBlobProps {
  blob: Blob
  fileName: string
  format?: EnumMimeTypes
}

export const downloadFileFromBlob = ({
  blob,
  fileName,
  format = EnumMimeTypes.PDF,
}: DownloadFileFromBlobProps) => {
  const url = getFileUrl(blob, format)

  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  // Append to html page
  document.body.appendChild(link)
  // Force download
  link.click()
  // Clean up and remove the link
  link.parentNode?.removeChild(link)
}

interface DownloadFileFromURLProps {
  url: string
  fileName: string
  format?: EnumMimeTypes
}

export const downloadFileFromURL = async ({
  url,
  fileName,
  format = EnumMimeTypes.PDF,
}: DownloadFileFromURLProps) => {
  const blob = await getBlobFromURL(url)

  downloadFileFromBlob({ blob, fileName, format })
}

export const getBlobFromURL = async (url: string) => {
  const response = await fetch(url)
  const blob = await response.blob()

  return blob
}
