import { ProviderApi } from '@blank/api'
import { QueryKey } from '@tanstack/react-query'
import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import { QueryOptions } from 'modules/auth/types/queries'
import { fetchProviders, FetchProvidersParams } from '../api/fetchProviders'
import { EnumBankQueryKeys } from './keys'

interface Props<TData> {
  queryOptions?: QueryOptions<ProviderApi[], Error, TData>
  fetchParams?: FetchProvidersParams
}

export const useProvidersQuery = <TData = ProviderApi[]>({
  queryOptions,
  fetchParams,
}: Props<TData> = {}) => {
  const queryKey: QueryKey = [
    EnumBankQueryKeys.PROVIDERS_LIST,
    ...(fetchParams ? [fetchParams] : []),
  ]

  return useQueryWithAuth({
    queryKey,
    queryFn: async (_context, accessToken) =>
      fetchProviders({ accessToken, fetchParams }),
    ...queryOptions,
  })
}
