import { mergeClasses } from '@blank/utilities'
import { useMemo } from 'react'
import { TagColor, TagVariant } from './Tag'

type ColorClassNames = Record<TagColor, string>

const THEME_BASE: ColorClassNames = {
  gray: 'text-info',
  primary: 'text-primary',
  success: 'text-success',
  warning: 'text-warning',
  danger: 'text-danger',
  white: '',
}

const THEME_VARIANT: Record<TagVariant, ColorClassNames> = {
  plain: {
    gray: 'bg-info-90',
    primary: 'bg-primary-90',
    success: 'bg-success-90',
    warning: 'bg-warning-90 ',
    danger: 'bg-danger-90',
    white: 'bg-white',
  },
  outlined: {
    gray: 'border-info',
    primary: 'border-primary',
    success: 'border-success',
    warning: 'border-warning',
    danger: 'border-danger',
    white: 'border-white text-white',
  },
}

interface Props {
  variant: TagVariant
  color: TagColor
}

export const useTheme = ({ variant, color }: Props) => {
  const themeClassName = useMemo(
    () => mergeClasses(THEME_BASE[color], THEME_VARIANT[variant]?.[color]),
    [variant, color]
  )

  return { themeClassName }
}
