import { LimitParametersRuleApi } from '@blank/api'
import { useIncreaseTransferLimitsRequestModal } from 'modules/bank/modals/transfer/IncreaseTransferLimitsRequestModal/useIncreaseTransferLimitsRequestModal'
import { RoundedElevatedCard } from 'modules/common/components/RoundedElevatedCard/RoundedElevatedCard'
import { IconPen } from 'modules/common/components/_icons/icons'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { BankingLimitProgress } from '../BankingLimitProgress/BankingLimitProgress'
import { BankingLimits } from '../BankingLimits/BankingLimits'

interface Props {
  dailyLimit: LimitParametersRuleApi
  monthlyLimit: LimitParametersRuleApi
  withCurrentExpenses?: boolean
}

export const BankingLimitsTransferCard = ({
  dailyLimit,
  monthlyLimit,
  withCurrentExpenses,
}: Props) => {
  const { t } = useTranslation('bank-components')
  const { showModal: showIncreaseTransferLimitsRequestModal } =
    useIncreaseTransferLimitsRequestModal()

  return (
    <RoundedElevatedCard>
      <div className="flex flex-wrap justify-between">
        <h3>{t('bankingLimitsTransferCard.title')}</h3>
        <Button
          variant="tertiary"
          className="!p-0"
          color="default"
          icon={<IconPen />}
          onClick={showIncreaseTransferLimitsRequestModal}
          type="button"
        >
          {t('bankingLimitsTransferCard.manageLimits')}
        </Button>
      </div>
      {withCurrentExpenses ? (
        <div className="divide-y divide-info-100">
          <BankingLimitProgress
            limit={dailyLimit}
            frequency="daily"
            className="py-4"
          />
          <BankingLimitProgress
            limit={monthlyLimit}
            frequency="monthly"
            className="pt-4"
          />
        </div>
      ) : (
        <BankingLimits
          className="mt-4"
          dailyLimit={dailyLimit}
          monthlyLimit={monthlyLimit}
        />
      )}
    </RoundedElevatedCard>
  )
}
