import { LimitParametersRuleApi } from '@blank/api'
import { RoundedElevatedCard } from 'modules/common/components/RoundedElevatedCard/RoundedElevatedCard'
import { IconPen } from 'modules/common/components/_icons/icons'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { EnumRoutes } from 'modules/common/routes'
import { BankingLimitProgress } from '../BankingLimitProgress/BankingLimitProgress'

interface Props {
  dailyLimit: LimitParametersRuleApi
  monthlyLimit: LimitParametersRuleApi
  withManageLimitButton: boolean
  isManageLimitsButtonDisabled?: boolean
}

export const BankingLimitsPaymentCard = ({
  dailyLimit,
  monthlyLimit,
  withManageLimitButton,
  isManageLimitsButtonDisabled = false,
}: Props) => {
  const { t } = useTranslation('bank-components')

  return (
    <RoundedElevatedCard>
      <div className="flex flex-wrap justify-between">
        <h3>{t('bankingLimitsPaymentCard.title')}</h3>
        {withManageLimitButton && (
          <Button
            variant="tertiary"
            className="!p-0"
            color="default"
            icon={<IconPen />}
            disabled={isManageLimitsButtonDisabled}
            href={EnumRoutes.ACCOUNT_CARD}
          >
            {t('bankingLimitsPaymentCard.manageLimits')}
          </Button>
        )}
      </div>
      <div className="divide-y divide-info-100">
        <BankingLimitProgress
          limit={dailyLimit}
          frequency="daily"
          className="py-4"
        />
        <BankingLimitProgress
          limit={monthlyLimit}
          frequency="monthly"
          className="pt-4"
        />
      </div>
    </RoundedElevatedCard>
  )
}
