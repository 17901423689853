import { useAuthenticatedUserCorporateQuery } from 'modules/auth/queries/useAuthenticatedUserCorporateQuery'
import { useCountryConfig } from 'modules/common/hooks/useCountryConfig'
import { ContentModalProps } from 'modules/common/modals/useContentModal/useContentModal'
import { useTypeformModal } from 'modules/common/modals/useTypeformModal/useTypeformModal'
import { useIncreaseTransferLimitsRequestSuccessModal } from './useIncreaseTransferLimitsRequestSuccessModal'

export const useIncreaseTransferLimitsRequestModal = (
  props?: Omit<ContentModalProps, 'content'>
) => {
  const {
    data: { corporate, user },
    isLoading,
    isError,
    errorRefetch,
  } = useAuthenticatedUserCorporateQuery()

  const { showModal: showSuccessModal } =
    useIncreaseTransferLimitsRequestSuccessModal()

  const { increaseTransferLimitTypeformId } = useCountryConfig()

  return useTypeformModal({
    formId: increaseTransferLimitTypeformId,
    onTypeformSubmitted: () => {
      showSuccessModal()
    },
    hiddenFields: {
      email: user?.email || '',
      company_name: corporate?.information?.name || '',
      first_name: corporate?.requester?.firstName || '',
      last_name: corporate?.requester?.lastName || '',
      corporate_id: corporate?.id || '',
    },
    isLoading,
    error: { isError, errorRefetch },
    ...props,
  })
}
