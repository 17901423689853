import { Link } from 'modules/common/components/Link/Link'
import { IconGift } from 'modules/common/components/_icons/icons'
import {
  Message,
  MessageProps,
} from 'modules/common/components/_ui/Message/Message'
import { MessageSkeleton } from 'modules/common/components/_ui/Skeletons/MessageSkeleton/MessageSkeleton'
import { useCountryExternalLinks } from 'modules/common/hooks/useCountryExternalLinks'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useCorporateSponsorshipQuery } from 'modules/corporate/queries/useCorporateSponsorshipQuery'
import { Corporate } from 'modules/corporate/types/corporates'
import { Trans } from 'next-i18next'

interface Props {
  isOnboardingRefused: boolean
  corporate: Corporate
  type: MessageProps['type']
}
export const PendingSponsorshipMessage = ({
  isOnboardingRefused,
  corporate,
  type,
}: Props) => {
  const { t } = useTranslation('corporate-components')

  const { data: sponsorship, isLoading: isCorporateSponsorshipLoading } =
    useCorporateSponsorshipQuery(corporate.id)
  const { hasPendingRefereeVoucherAffiliation } = sponsorship || {}
  const externalLinks = useCountryExternalLinks()

  if (isOnboardingRefused) {
    return null
  }

  if (isCorporateSponsorshipLoading) {
    return <MessageSkeleton />
  }

  if (hasPendingRefereeVoucherAffiliation) {
    return (
      <Message
        title={t('pendingSponsorshipMessage.title')}
        content={
          <Trans
            parent="p"
            components={{
              linkComponent: (
                <Link
                  href={externalLinks.sponsorshipFAQ}
                  className="font-bold underline"
                />
              ),
            }}
          >
            {t('pendingSponsorshipMessage.content')}
          </Trans>
        }
        icon={<IconGift />}
        withIcon
        type={type}
      />
    )
  }

  return null
}
