import { EnumTransferMethod } from '@blank/api'
import { TransferMethodFormData } from 'modules/bank/modals/transfer/TransferMethodFormModal/useTransferMethodFormModal'
import { RadioButton } from 'modules/common/components/_ui/FormControl/RadioButton/RadioButton'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { UseFormRegister } from 'react-hook-form'

interface Props {
  register: UseFormRegister<TransferMethodFormData>
}

export const TransferMethodFormModalContent = ({ register }: Props) => {
  const { t } = useTranslation('bank-components')

  const transferMethodSecondaryLabelMap: Record<
    EnumTransferMethod,
    string | undefined
  > = {
    [EnumTransferMethod.DIRECT]: undefined,
    [EnumTransferMethod.DEFERRED]: t(
      'transferMethodModal.deferredTransferInfo'
    ),
    [EnumTransferMethod.RECURRING]: undefined,
  }

  return (
    <div className="text-start">
      {Object.values(EnumTransferMethod).map((transferMethod) => (
        <RadioButton
          id={`radio-button-${transferMethod}`}
          key={transferMethod}
          primaryLabel={t('common.transferMethod', {
            context: transferMethod,
          })}
          secondaryLabel={transferMethodSecondaryLabelMap[transferMethod]}
          value={transferMethod}
          variant="outline"
          {...register('transferMethod')}
        />
      ))}
    </div>
  )
}
