import { EnumOfferType } from '@blank/api'
import { useDisplayActivateTokamakCardModalOnMount } from 'modules/bank/modals/card/useDisplayActivateTokamakCardModalOnMount'
import { Seo } from 'modules/common/components/Seo/Seo'
import { useSelectSidebarMenu } from 'modules/common/components/Sidebar/hooks/useSelectSidebarMenu'
import { IconConsultFaqs } from 'modules/common/components/_icons/icons'
import { LayoutDashboard } from 'modules/common/components/_layouts/LayoutDashboard'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { useCountryExternalLinks } from 'modules/common/hooks/useCountryExternalLinks'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { isDay } from 'modules/common/lib/helpers/date/isDay'
import { externalLinkProps } from 'modules/common/lib/helpers/externalLinkProps'
import { BlankPage } from 'modules/common/types/page'
import { EnumSidebarMenu } from 'modules/common/types/sidebar'
import { AccountingContainer } from 'modules/home/components/AccountingContainer/AccountingContainer'
import { HomePageBanners } from 'modules/home/components/HomePageBanners/HomePageBanners'
import { InternalAccountContainer } from 'modules/home/components/InternalAccountContainer/InternalAccountContainer'
import { InvoicingContainer } from 'modules/home/components/InvoicingContainer/InvoicingContainer'
import { useHomePageQueries } from 'modules/home/hooks/useHomePageQueries'
import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

const HomePage: BlankPage = () => {
  const { t } = useTranslation('common-page-home')
  const externalLinks = useCountryExternalLinks()

  useSelectSidebarMenu({ menuId: EnumSidebarMenu.HOME })

  useDisplayActivateTokamakCardModalOnMount()

  const {
    data: { corporateOffer, firstName },
    isSuccess,
  } = useHomePageQueries()

  return (
    <div className="flex w-full flex-col">
      <Seo title={t('meta.title')} noindex />
      <HomePageBanners />
      <div className="grid h-fit w-full grid-cols-2 gap-6 px-10 py-8">
        <div className="col-span-2 flex items-center justify-between">
          <div>
            <h2>
              {t(isDay() ? 'greetings_day' : 'greetings_night', {
                firstName,
              })}
            </h2>
            <p className="text-info-400">{t('introduction')}</p>
          </div>
          {externalLinks.faq && (
            <Button
              variant="secondary"
              color="default"
              href={externalLinks.faq}
              {...externalLinkProps}
              icon={<IconConsultFaqs />}
            >
              {t('faqButtonLabel')}
            </Button>
          )}
        </div>
        {isSuccess &&
          corporateOffer?.offerType !==
            EnumOfferType.STRICTLY_MANAGEMENT_TOOLS && (
            <div className="col-span-2">
              <InternalAccountContainer />
            </div>
          )}
        <AccountingContainer />
        <InvoicingContainer />
      </div>
    </div>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale as string, [
      'common',
      'common-page-home',
      'common-components',
      'bank-components',
      'home-components',
      'invoicing-components',
      'billing-components',
      'corporate-components',
      'settings-components',
    ])),
  },
})

HomePage.getLayout = (page) => (
  <LayoutDashboard className="bg-info-90">{page}</LayoutDashboard>
)
HomePage.type = 'authenticated'

export default HomePage
