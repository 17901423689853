import { mergeClasses } from '@blank/utilities'
import { RadioButtonVariant } from './RadioButton'
import { RADIO_BUTTON_THEME } from './RadioButton.theme'

interface Props {
  variant: RadioButtonVariant
  disabled: boolean | undefined
  hasSecondaryLabel: boolean
  withIcon: boolean
}

export const useRadioButtonTheme = ({
  variant,
  disabled,
  hasSecondaryLabel,
  withIcon,
}: Props) => {
  const inputClassName = mergeClasses(
    RADIO_BUTTON_THEME['input']['common']['base'],
    RADIO_BUTTON_THEME['input'][variant]['base'],
    {
      [RADIO_BUTTON_THEME['input']['common']['disabled']]: disabled,
      [RADIO_BUTTON_THEME['input'][variant]['disabled']]: disabled,
    }
  )

  const labelClassName = mergeClasses(
    RADIO_BUTTON_THEME['label']['common']['base'],
    RADIO_BUTTON_THEME['label'][variant]['base'],
    {
      [RADIO_BUTTON_THEME['label']['common']['disabled']]: disabled,
      [RADIO_BUTTON_THEME['label'][variant]['disabled']]: disabled,
      'py-4': variant === 'outline' && !hasSecondaryLabel,
      'py-2': variant === 'outline' && hasSecondaryLabel,
      'gap-3': withIcon,
      'py-3 !pl-4': withIcon && variant === 'outline',
      '!pl-0': withIcon && variant === 'default',
    }
  )

  const secondaryLabelClassName = mergeClasses(
    RADIO_BUTTON_THEME['secondaryLabel']['base'],
    {
      [RADIO_BUTTON_THEME['secondaryLabel']['disabled']]: disabled,
    }
  )

  return { inputClassName, labelClassName, secondaryLabelClassName }
}
