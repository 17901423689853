import { RadioButtonVariant } from './RadioButton'

interface RadioButtonState {
  base: string
  disabled: string
}

interface RadioButtonTheme {
  input: {
    [key in RadioButtonVariant | 'common']: RadioButtonState
  }
  label: {
    [key in RadioButtonVariant | 'common']: RadioButtonState
  }
  secondaryLabel: RadioButtonState
}

export const RADIO_BUTTON_THEME: RadioButtonTheme = {
  input: {
    common: {
      base: 'peer absolute left-0 top-0 bottom-0 my-auto cursor-pointer border-2 border-info-400 text-primary checked:border-primary-600 focus:!border-primary-600 focus:ring-0 focus:ring-offset-0',
      disabled:
        'cursor-default !border-info-300 bg-info-100 checked:!bg-[url("/assets/svg/radio-button-disabled.svg")]',
    },
    default: {
      base: '',
      disabled: '',
    },
    outline: {
      base: 'ml-4',
      disabled: '',
    },
  },
  label: {
    common: {
      base: 'cursor-pointer flex items-center peer-checked:text-info-600',
      disabled: 'cursor-default',
    },
    default: {
      base: 'pl-6 text-info-400',
      disabled: '',
    },
    outline: {
      base: 'rounded-lg border border-info-200 pl-10 pr-4 text-info-600 peer-checked:border-primary peer-checked:font-bold peer-checked:text-primary peer-checked:bg-primary-90',
      disabled: '!border-info-200 !bg-info-100 !text-info-400',
    },
  },
  secondaryLabel: {
    base: 'text-sm font-normal leading-4 text-info-400',
    disabled: '!text-info-400',
  },
}
