import { useAuthenticatedUserQuery } from 'modules/auth/queries/useAuthenticatedUserQuery'
import { useCorporateUserName } from 'modules/corporate/hooks/useCorporateUserName'
import { useCorporateOfferQuery } from 'modules/corporate/queries/useCorporateOfferQuery'

export const useHomePageQueries = () => {
  const { data: authenticatedUser } = useAuthenticatedUserQuery()

  const { data: corporateOffer, isSuccess } = useCorporateOfferQuery(
    authenticatedUser?.corporateId
  )

  const { firstName } = useCorporateUserName()

  return {
    data: { corporateOffer, firstName },
    isSuccess,
  }
}
