import { AccountApi, ProviderApi } from '@blank/api'
import { buildTimeConfig } from 'config'
import { DropdownMenuAccount } from 'modules/bank/components/transaction/TransactionListCard/AccountSelectionDropdown/AccountSelectionDropdown'
import { useSplitAccountListBySource } from 'modules/bank/hooks/account/useSplitAccountListBySource'
import { useMemo } from 'react'

interface Props {
  accounts: AccountApi[]
  providers: ProviderApi[]
}

export const useDropdownMenuAccounts = ({
  accounts,
  providers,
}: Props): DropdownMenuAccount[] => {
  const { internalAccount, externalAccounts } =
    useSplitAccountListBySource(accounts)

  const internalAccountItem = useMemo(
    () =>
      internalAccount
        ? {
            bankName: buildTimeConfig.internalBankName,
            accountName: internalAccount.name ?? '',
            id: internalAccount.id,
            accountSource: internalAccount.source,
          }
        : null,
    [internalAccount]
  )

  const externalAccountItems = useMemo(
    () =>
      externalAccounts.map(({ providerId, name, id, source }) => {
        const provider = providers.find(
          (provider) => providerId === provider.id
        )
        return {
          bankName: provider?.name ?? '',
          accountName: name ?? '',
          id,
          externalAccountIconUrl: provider?.logoUrl,
          accountSource: source,
        }
      }),
    [externalAccounts, providers]
  )

  return [
    ...(internalAccountItem ? [internalAccountItem] : []),
    ...externalAccountItems,
  ]
}
