import { AccountApi, CardApi, EnumAccountCBSSource } from '@blank/api'
import { BankingLimitsPaymentCard } from 'modules/bank/components/account/BankingLimitsPaymentCard/BankingLimitsPaymentCard'
import { BankingLimitsPaymentCardSkeleton } from 'modules/bank/components/account/BankingLimitsPaymentCard/BankingLimitsPaymentCardSkeleton'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { InternalAccountCardError } from '../../InternalAccountCardError/InternalAccountCardError'
import { useInternalCardLimitsQueries } from './useBankCardLimitsQueries'

interface Props {
  currentCard: CardApi
  internalAccount: AccountApi
}

export const BankCardLimitsCardContainer = ({
  currentCard,
  internalAccount: { isBlockedInvest, cbsSource },
}: Props) => {
  const { t } = useTranslation('bank-components')
  const isTokamakAccount = cbsSource === EnumAccountCBSSource.TOKAMAK

  const {
    data: { monthlyLimit, dailyLimit },
    isError,
    isLoading,
    refetch,
  } = useInternalCardLimitsQueries(currentCard)

  if (isError) {
    return (
      <InternalAccountCardError
        title={t('bankingLimitsPaymentCard.title')}
        onReload={refetch}
      />
    )
  }

  if (isLoading || !(dailyLimit && monthlyLimit)) {
    return <BankingLimitsPaymentCardSkeleton />
  }

  return (
    <BankingLimitsPaymentCard
      dailyLimit={dailyLimit}
      monthlyLimit={monthlyLimit}
      withManageLimitButton={!isTokamakAccount}
      isManageLimitsButtonDisabled={isBlockedInvest}
    />
  )
}
