import { getIsKycComplete } from 'modules/auth/lib/utils/getIsKycComplete'
import { useCorporateLifecycleQueries } from 'modules/corporate/hooks/useCorporateLifecycleQueries'
import { InternalAccountOpeningCard } from '../InternalAccountOpeningCard/InternalAccountOpeningCard'
import { BankCardContainer } from './BankCardContainer/BankCardContainer'
import { InternalAccountContainerErrorReload } from './InternalAccountContainerErrorReload'
import { InternalAccountContainerSkeleton } from './InternalAccountContainerSkeleton'
import { InternalAccountInformationCardContainer } from './InternalAccountInformationCardContainer/InternalAccountInformationCardContainer'
import { InternalAccountTransferLimitsCardContainer } from './InternalAccountTransferLimitsCardContainer/InternalAccountTransferLimitsCardContainer'

export const InternalAccountContainer = () => {
  const {
    data: { lifecycle, corporate },
    errorRefetch,
    isError,
    isLoading,
  } = useCorporateLifecycleQueries()

  const isKycComplete = getIsKycComplete(lifecycle)

  if (isLoading) {
    return <InternalAccountContainerSkeleton />
  }

  if (isError || !lifecycle || !corporate) {
    return <InternalAccountContainerErrorReload onReload={errorRefetch} />
  }

  if (isKycComplete) {
    return (
      <div className="grid grid-cols-3 gap-x-6">
        <InternalAccountInformationCardContainer />
        <InternalAccountTransferLimitsCardContainer withCurrentExpenses />
        <BankCardContainer />
      </div>
    )
  }

  return (
    <InternalAccountOpeningCard lifecycle={lifecycle} corporate={corporate} />
  )
}
