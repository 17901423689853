import { RoundedElevatedCard } from 'modules/common/components/RoundedElevatedCard/RoundedElevatedCard'
import { Skeleton } from 'modules/common/components/_ui/Skeletons/Skeleton/Skeleton'
import { DocumentListSkeleton } from './DocumentList/DocumentListSkeleton'

export const DocumentListCardSkeleton = () => (
  <RoundedElevatedCard>
    <div className="flex items-center justify-between gap-2">
      <Skeleton className="h-6 w-40" />
      <div className="flex items-center gap-2">
        <Skeleton className="h-6 w-6 rounded-lg" />
        <Skeleton className="h-6 w-36 rounded-lg" />
      </div>
    </div>
    <DocumentListSkeleton />
  </RoundedElevatedCard>
)
