import { RoundedElevatedCard } from 'modules/common/components/RoundedElevatedCard/RoundedElevatedCard'
import { Skeleton } from 'modules/common/components/_ui/Skeletons/Skeleton/Skeleton'
import { BankingLimitProgressSkeleton } from '../BankingLimitProgress/BankingLimitProgressSkeleton'

export const BankingLimitsPaymentCardSkeleton = () => (
  <RoundedElevatedCard className="w-full">
    <div className="flex items-center justify-between">
      <Skeleton className="h-6 w-44" />
      <Skeleton className="h-4 w-24" />
    </div>
    <div className="mt-4 divide-y divide-info-100">
      <BankingLimitProgressSkeleton className="pb-5" />
      <BankingLimitProgressSkeleton className="pt-5" />
    </div>
  </RoundedElevatedCard>
)
