import { useActivateTokamakCardModal } from 'modules/bank/modals/card/ActivateTokamakCardModal/useActivateTokamakCardModal'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { useTranslation } from 'modules/common/hooks/useTranslation'

export const ActivateTokamakCardButton = () => {
  const { t } = useTranslation('home-components')

  const { showModal: showActivateTokamakCardModal } =
    useActivateTokamakCardModal()

  return (
    <Button
      onClick={showActivateTokamakCardModal}
      variant="primary"
      className="mt-auto w-fit"
      color="default"
    >
      {t('bankCardActivationCard.ctaLabel')}
    </Button>
  )
}
