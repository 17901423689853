import { RoundedElevatedCard } from 'modules/common/components/RoundedElevatedCard/RoundedElevatedCard'
import { Skeleton } from 'modules/common/components/_ui/Skeletons/Skeleton/Skeleton'

export const InternalAccountInformationCardSkeleton = () => (
  <RoundedElevatedCard>
    <div className="divide-borders-card divide-y">
      <div className="flex space-x-2 pb-4">
        <Skeleton className="h-6 w-6" />
        <div className="flex-1">
          <Skeleton className="mb-4 h-6 w-36" />
          <Skeleton className="mb-2 h-5 w-10" />
          <Skeleton className="h-5 w-full max-w-xs" />
        </div>
      </div>
      <div className="flex items-center justify-between space-x-1 pb-3 pt-7">
        <Skeleton className="h-6 w-40" />
        <Skeleton className="h-8 w-32" />
      </div>
    </div>
  </RoundedElevatedCard>
)
