import { EnumCardStatusCode, EnumMigrationStatus } from '@blank/api'
import { BankingLimitsPaymentCardSkeleton } from 'modules/bank/components/account/BankingLimitsPaymentCard/BankingLimitsPaymentCardSkeleton'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { InternalAccountCardError } from '../InternalAccountCardError/InternalAccountCardError'
import { BankCardActivationCard } from './BankCardActivationCard/BankCardActivationCard'
import { BankCardLimitsCardContainer } from './BankCardLimitsCardContainer/BankCardLimitsCardContainer'
import { useBankCardContainerQueries } from './useBankCardContainerQueries'

export const BankCardContainer = () => {
  const { t } = useTranslation('bank-components')

  const {
    data: { corporate, currentCard, offer, internalAccount },
    isError,
    isLoading,
    refetch,
  } = useBankCardContainerQueries()

  if (isError) {
    return (
      <InternalAccountCardError
        title={t('bankingLimitsPaymentCard.title')}
        onReload={refetch}
      />
    )
  }

  if (isLoading || !(currentCard && corporate && offer && internalAccount)) {
    return <BankingLimitsPaymentCardSkeleton />
  }

  const isCardToBeActivated =
    currentCard?.statusCode === EnumCardStatusCode.PREACTIVATED ||
    internalAccount.migrationStatus === EnumMigrationStatus.CARD_ORDERED

  if (isCardToBeActivated) {
    return <BankCardActivationCard offer={offer} account={internalAccount} />
  }

  return (
    <BankCardLimitsCardContainer
      currentCard={currentCard}
      internalAccount={internalAccount}
    />
  )
}
