import { BankingLimitsTransferCard } from 'modules/bank/components/account/BankingLimitsTransferCard/BankingLimitsTransferCard'
import { BankingLimitsTransferCardSkeleton } from 'modules/bank/components/account/BankingLimitsTransferCard/BankingLimitsTransferCardSkeleton'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { InternalAccountCardError } from '../InternalAccountCardError/InternalAccountCardError'
import { useInternalAccountTransferLimitsQueries } from './useInternalAccountTransferLimitsQueries'

interface Props {
  withCurrentExpenses?: boolean
}

export const InternalAccountTransferLimitsCardContainer = ({
  withCurrentExpenses,
}: Props) => {
  const { t } = useTranslation('bank-components')

  const {
    data: {
      transferLimits: { monthlyLimit, dailyLimit },
    },
    isError,
    isLoading,
    errorRefetch,
  } = useInternalAccountTransferLimitsQueries()

  if (isError) {
    return (
      <InternalAccountCardError
        title={t('bankingLimitsTransferCard.title')}
        onReload={errorRefetch}
      />
    )
  }

  if (isLoading || !(monthlyLimit && dailyLimit)) {
    return (
      <BankingLimitsTransferCardSkeleton
        withCurrentExpenses={withCurrentExpenses}
      />
    )
  }

  return (
    <BankingLimitsTransferCard
      dailyLimit={dailyLimit}
      monthlyLimit={monthlyLimit}
      withCurrentExpenses={withCurrentExpenses}
    />
  )
}
