import { EnumDocumentType } from '@blank/api'
import { mergeClasses } from '@blank/utilities'
import {
  IconDocumentEdit,
  IconInvoiceAdd,
  IconPlusRounded,
} from 'modules/common/components/_icons/icons'
import {
  Button,
  ButtonProps,
} from 'modules/common/components/_ui/Button/Button'
import { DropdownMenu } from 'modules/common/components/_ui/DropdownMenu/DropdownMenu'
import { DropdownMenuItem } from 'modules/common/components/_ui/DropdownMenu/DropdownMenuItem'
import { useCountryConfig } from 'modules/common/hooks/useCountryConfig'
import { useDropdownMenu } from 'modules/common/hooks/useDropdownMenu'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useRedirectToDocumentCreationFlow } from 'modules/invoicing/flows/document/useRedirectToDocumentCreationFlow'

interface Props {
  customerId?: string
  disabled?: boolean
  variant?: ButtonProps['variant']
  className?: string
}

export const NewDocumentButton = ({
  customerId,
  disabled = false,
  variant = 'primary',
  className,
}: Props) => {
  const { t } = useTranslation('invoicing-components')

  const {
    invoicing: { canCreateEstimateDocument },
  } = useCountryConfig()

  const { registerDropdownButton, registerDropdownMenu } = useDropdownMenu()

  const { redirectToDocumentCreation } = useRedirectToDocumentCreationFlow()

  return (
    <>
      <Button
        variant={variant}
        color="default"
        {...registerDropdownButton()}
        className={mergeClasses(
          'flex flex-row items-center justify-center',
          className
        )}
        disabled={disabled}
        icon={<IconPlusRounded />}
        data-testid="create-document-dropdown-button"
      >
        {t('newDocumentButton.label')}
      </Button>
      <DropdownMenu {...registerDropdownMenu()}>
        {canCreateEstimateDocument && (
          <DropdownMenuItem
            onClick={() =>
              redirectToDocumentCreation({
                documentType: EnumDocumentType.ESTIMATE,
                customerId,
              })
            }
            icon={<IconInvoiceAdd className="text-primary" />}
            data-testid="create-estimate-button"
          >
            {t('newDocumentButton.estimateCta')}
          </DropdownMenuItem>
        )}

        <DropdownMenuItem
          onClick={() =>
            redirectToDocumentCreation({
              documentType: EnumDocumentType.INVOICE,
              customerId,
            })
          }
          icon={<IconDocumentEdit className="text-primary" />}
          data-testid="create-invoice-button"
        >
          {t('newDocumentButton.invoiceCta')}
        </DropdownMenuItem>
      </DropdownMenu>
    </>
  )
}
