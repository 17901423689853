import { AccountApi, EnumMigrationStatus } from '@blank/api'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { EnumRoutes } from 'modules/common/routes'
import { ActivateTokamakCardButton } from 'modules/home/components/ActivateTokamakCardButton/ActivateTokamakCardButton'

interface Props {
  account: AccountApi
}

export const BankCardActivationCardButton = ({ account }: Props) => {
  const { t } = useTranslation('home-components')

  if (account.migrationStatus === EnumMigrationStatus.CARD_ORDERED) {
    return <ActivateTokamakCardButton />
  }

  return (
    <Button
      href={EnumRoutes.ACCOUNT_CARD}
      variant="primary"
      className="mt-auto w-fit"
      color="default"
    >
      {t('bankCardActivationCard.ctaLabel')}
    </Button>
  )
}
