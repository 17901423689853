import { EnumTransactionPaymentMethod, TransactionApi } from '@blank/api'
import { mergeClasses } from '@blank/utilities'
import {
  IconCardDeposit,
  IconCardWithdraw,
  IconCashTransferIn,
  IconCashTransferOut,
} from 'modules/common/components/_icons/icons'
import { Skeleton } from 'modules/common/components/_ui/Skeletons/Skeleton/Skeleton'

interface Props {
  transaction: TransactionApi
  className?: string
}

export const TransactionIcon = ({
  transaction: { amount, paymentMethod },
  className,
}: Props) => {
  const isNegative = parseFloat(amount) < 0

  const isCard = paymentMethod === EnumTransactionPaymentMethod.CARD

  const isCashDeposit = !isNegative && !isCard

  const isCashWithdraw = isNegative && !isCard

  const isCardDeposit = !isNegative && isCard

  if (isCashDeposit) {
    return (
      <IconCashTransferIn
        className={mergeClasses('h-6 w-6 text-success ', className)}
      />
    )
  } else if (isCashWithdraw) {
    return (
      <IconCashTransferOut
        className={mergeClasses('h-6 w-6 text-info-600', className)}
      />
    )
  } else if (isCardDeposit) {
    return (
      <IconCardDeposit
        className={mergeClasses('h-6 w-6 text-success ', className)}
      />
    )
  } else {
    return (
      <IconCardWithdraw
        className={mergeClasses('h-6 w-6 text-info-600', className)}
      />
    )
  }
}

export const TransactionIconSkeleton = ({
  className,
}: {
  className: string
}) => <Skeleton className={mergeClasses('h-6 w-6 rounded-sm', className)} />
