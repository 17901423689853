import { AccountApi, EnumAccountSource } from '@blank/api'
import { useMemo } from 'react'

export const useSplitAccountListBySource = (accounts: AccountApi[] = []) => {
  const internalAccount = useMemo(
    () =>
      accounts.find((account) => account.source === EnumAccountSource.INTERNAL),
    [accounts]
  )

  const externalAccounts = useMemo(
    () =>
      accounts.filter(
        (account) => account.source === EnumAccountSource.EXTERNAL
      ),
    [accounts]
  )

  return { internalAccount, externalAccounts }
}
