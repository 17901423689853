import { EnumLifecycleStep } from '@blank/api'
import { useBanner } from 'modules/common/hooks/useBanner'
import { EnumBannerKey } from 'modules/common/types/banner'
import { useTDAEDiscoveryBannerQueries } from './useTDAEDiscoveryBannerQueries'

export const useTDAEDiscoveryBanner = () => {
  const {
    data: {
      canAccessTdaeUrssafService,
      hasExternalAccount,
      hasInternalAccount,
      isTDAEServiceNeverSubscribed,
      lifecycleTDAEServiceStatus,
    },
    isSuccess,
  } = useTDAEDiscoveryBannerQueries()

  const shouldDisplayTDAEDiscoveryBannerMessage = Boolean(
    isSuccess &&
      canAccessTdaeUrssafService &&
      isTDAEServiceNeverSubscribed &&
      (hasInternalAccount ||
        (hasExternalAccount &&
          lifecycleTDAEServiceStatus === EnumLifecycleStep.TODO))
  )

  return useBanner({
    bannerKey: EnumBannerKey.TDAE_URSSAF_DISCOVERY,
    displayCondition: shouldDisplayTDAEDiscoveryBannerMessage,
  })
}
