import { TransactionApi } from '@blank/api'
import { mergeClasses } from '@blank/utilities'
import { formatTransactionAmount } from 'modules/bank/utils/format/transactions'

export interface TransactionAmountProps {
  transaction: Pick<
    TransactionApi,
    'amount' | 'originalAmount' | 'currency' | 'originalCurrency'
  >
}

export const TransactionAmount = ({
  transaction: { amount, currency, originalCurrency, originalAmount },
}: TransactionAmountProps) => {
  const amountFloat = parseFloat(amount)
  const originalAmountFloat = originalAmount ? parseFloat(originalAmount) : 0

  return (
    <div>
      <h3 className={mergeClasses({ 'text-success': amountFloat >= 0 })}>
        {formatTransactionAmount(amountFloat, currency)}
      </h3>
      {!!originalCurrency && originalCurrency !== currency && (
        <span className="block font-bold leading-5 text-info-400">
          {formatTransactionAmount(originalAmountFloat, originalCurrency)}
        </span>
      )}
    </div>
  )
}
