import { EnumAccountSource } from '@blank/api'
import { IconInternalAccount } from 'modules/common/components/_icons/IconInternalAccount/IconInternalAccount'
import { Badge, BadgeSkeleton } from 'modules/common/components/_ui/Badge/Badge'

export interface BankBadgeProps {
  logoUrl?: string
  accountSource: EnumAccountSource | undefined
}

export const BankBadge = ({ logoUrl, accountSource }: BankBadgeProps) => {
  return (
    <Badge className="p-2">
      {accountSource === EnumAccountSource.INTERNAL ? (
        <IconInternalAccount />
      ) : (
        // We need to use `<img>` for external hosted logos to bypass next-image-unconfigured-host error
        // eslint-disable-next-line @next/next/no-img-element
        <img src={logoUrl} />
      )}
    </Badge>
  )
}

export const BankBadgeSkeleton = () => <BadgeSkeleton />
