import { Skeleton } from 'modules/common/components/_ui/Skeletons/Skeleton/Skeleton'

export const InternalAccountContainerSkeleton = () => (
  <div className="flex justify-between rounded-2xl border border-info-200 bg-white shadow-md">
    <div className="flex flex-col gap-6 p-10">
      <Skeleton className="h-8 w-12 rounded-lg" />
      <div className="flex flex-col gap-2">
        <Skeleton className="h-6 w-96 rounded-lg" />
        <Skeleton className="h-6 w-80 rounded-lg" />
      </div>
      <Skeleton className="h-14 w-60 rounded-lg" />
    </div>
  </div>
)
