import {
  IconCalendarSchedule,
  IconPenEdit,
  IconUsers,
} from 'modules/common/components/_icons/icons'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { EnumRoutes } from 'modules/common/routes'
import { useRouter } from 'next/router'
import { ProductTourCardProps } from '../ProductTourCard/ProductTourCard'

export const useInvoicingCardProps = (): ProductTourCardProps => {
  const { t } = useTranslation(['home-components', 'common'])

  const router = useRouter()

  return {
    title: t('invoicingProductCard.title'),
    description: t('invoicingProductCard.description'),
    bulletPoints: [
      {
        icon: <IconPenEdit />,
        description: t('invoicingProductCard.firstBulletPoint'),
      },
      {
        icon: <IconCalendarSchedule />,
        description: t('invoicingProductCard.secondBulletPoint'),
      },
      {
        icon: <IconUsers />,
        description: t('invoicingProductCard.thirdBulletPoint'),
      },
    ],
    firstCta: {
      label: t('invoicingProductCard.firstCtaLabel'),
      callback: () => router.push(EnumRoutes.INVOICING_DOCUMENTS_CONFIGURATION),
    },
    secondCta: {
      label: t('invoicingProductCard.secondCtaLabel'),
      callback: () => router.push(EnumRoutes.INVOICING_DOCUMENTS),
    },
  }
}
