import { TransactionListCard } from 'modules/bank/components/transaction/TransactionListCard/TransactionListCard'
import { TransactionListCardError } from 'modules/bank/components/transaction/TransactionListCard/TransactionListCardError'
import { TransactionListCardSkeleton } from 'modules/bank/components/transaction/TransactionListCard/TransactionListCardSkeleton'
import { useTransactionListCardQueries } from 'modules/bank/components/transaction/TransactionListCard/useTransactionListCardQueries'
import { ProductTourCard } from '../ProductTourCard/ProductTourCard'
import { useAccountingCardProps } from './useAccountingCardProps'

export const AccountingContainer = () => {
  const accountingCardProps = useAccountingCardProps()
  const {
    isLoading,
    isSuccess,
    isError,
    errorRefetch,
    data: { accounts, providers },
  } = useTransactionListCardQueries()

  return (
    <>
      {isLoading && <TransactionListCardSkeleton />}
      {isError && <TransactionListCardError onReload={errorRefetch} />}
      {isSuccess && accounts?.length === 0 && (
        <ProductTourCard {...accountingCardProps} />
      )}
      {isSuccess && accounts && providers && accounts.length > 0 && (
        <TransactionListCard accounts={accounts} providers={providers} />
      )}
    </>
  )
}
