import { mergeClasses } from '@blank/utilities'
import { Skeleton } from 'modules/common/components/_ui/Skeletons/Skeleton/Skeleton'

interface Props {
  className?: string
}

export const BankingLimitProgressSkeleton = ({ className }: Props) => (
  <div
    className={mergeClasses(
      'flex content-center items-center space-x-4',
      className
    )}
  >
    <Skeleton className="h-12 w-12 shrink-0 rounded-full" />
    <div className="space-y-3">
      <Skeleton className="h-4 w-32" />
      <Skeleton className="h-4 w-44" />
    </div>
  </div>
)
