import { LimitParametersRuleApi } from '@blank/api'
import { mergeClasses } from '@blank/utilities'
import { usePaymentLimit } from 'modules/bank/hooks/card/usePaymentLimit'
import { Amount } from 'modules/common/components/_ui/Amount/Amount'
import { CircleProgressBar } from 'modules/common/components/_ui/CircleProgressBar/CircleProgressBar'
import { useTranslation } from 'modules/common/hooks/useTranslation'

interface Props {
  limit: LimitParametersRuleApi
  frequency: 'daily' | 'monthly'
  className?: string
}

export const BankingLimitProgress = ({
  limit,
  frequency,
  className,
}: Props) => {
  const {
    values: { currentValue, maxValue, progressPercentage },
    theme: { circleBgColor, progressColor },
  } = usePaymentLimit({
    limit,
  })
  const { t } = useTranslation('bank-components')

  return (
    <div
      className={mergeClasses(
        'flex content-center items-center space-x-4',
        className
      )}
    >
      <CircleProgressBar
        progressColor={progressColor}
        circleBgColor={circleBgColor}
        size="48"
        strokeWidth="42"
        progressPercentage={progressPercentage}
        className="shrink-0"
      />
      <div className="space-y-2 font-bold">
        <p className="text-info-400">
          {t('bankingLimit.title', { context: frequency })}
        </p>
        <div className="flex items-baseline">
          <Amount amount={currentValue} className={progressColor} />
          <p className="font-amount">&nbsp;/&nbsp;</p>
          <Amount amount={maxValue} />
        </div>
      </div>
    </div>
  )
}
