import { useAuthenticatedUserQuery } from 'modules/auth/queries/useAuthenticatedUserQuery'
import {
  IconBarcodeScanner,
  IconDownloadArrow,
  IconPieChart,
} from 'modules/common/components/_icons/icons'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useCorporateOfferQuery } from 'modules/corporate/queries/useCorporateOfferQuery'
import { useRouter } from 'next/router'
import { ProductTourCardProps } from '../ProductTourCard/ProductTourCard'

export const useAccountingCardProps = (): ProductTourCardProps => {
  const { t } = useTranslation('home-components')

  const { data: user } = useAuthenticatedUserQuery()

  const { data: offer } = useCorporateOfferQuery(user?.corporateId)

  const router = useRouter()

  return {
    title: t('accountingProductCard.title'),
    description: t('accountingProductCard.description'),
    bulletPoints: [
      {
        icon: <IconPieChart />,
        description: t('accountingProductCard.firstBulletPoint'),
      },
      {
        icon: <IconBarcodeScanner />,
        description: t('accountingProductCard.secondBulletPoint'),
      },
      {
        icon: <IconDownloadArrow />,
        description: t('accountingProductCard.thirdBulletPoint'),
      },
    ],
    firstCta: {
      label: t('accountingProductCard.ctaLabel', {
        context: offer?.offerType,
      }),
      callback: () => router.push('/comptes'),
    },
  }
}
