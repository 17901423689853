import { Button } from 'modules/common/components/_ui/Button/Button'
import { Tag } from 'modules/common/components/_ui/Tag/Tag'

interface BulletPoint {
  icon: JSX.Element
  description: string
}

interface Action {
  label: string
  callback: () => void
}

export interface ProductTourCardProps {
  title: string
  disabled?: boolean
  bulletPoints: BulletPoint[]
  description: string
  firstCta: Action
  secondCta?: Action
  tag?: string
}

export const ProductTourCard = ({
  title,
  disabled,
  bulletPoints,
  description,
  firstCta,
  secondCta,
  tag,
}: ProductTourCardProps) => (
  <div className="relative overflow-hidden rounded-2xl border border-info-200 bg-white shadow-md">
    <div className="absolute h-full w-[6px] bg-primary" />
    <div className="flex h-full flex-col p-10">
      <div className="mb-6 flex items-center gap-3">
        <h2>{title}</h2>
        {tag && (
          <Tag variant="plain" color="primary" className="font-normal">
            {tag}
          </Tag>
        )}
      </div>
      <div className="flex flex-1 divide-x divide-info-200 text-lg">
        <ul className="flex w-1/2 shrink-0 flex-col gap-y-5 pr-6">
          {bulletPoints.map((bulletPoint) => (
            <li key={bulletPoint.description} className="flex items-center">
              <span className="mr-4 flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-primary-90 text-primary">
                {bulletPoint.icon}
              </span>
              <span className="mt-1">{bulletPoint.description}</span>
            </li>
          ))}
        </ul>
        <div className="flex flex-col gap-y-4 pl-6">
          <p>{description}</p>
          {[firstCta, secondCta].map(
            (cta) =>
              cta !== undefined && (
                <Button
                  key={cta.label}
                  variant="secondary"
                  color="default"
                  onClick={cta.callback}
                  disabled={disabled}
                >
                  {cta.label}
                </Button>
              )
          )}
        </div>
      </div>
    </div>
  </div>
)
