import { InternalAccountInformationCard } from 'modules/bank/components/account/InternalAccountInformationCard/InternalAccountInformationCard'
import { InternalAccountInformationCardSkeleton } from 'modules/bank/components/account/InternalAccountInformationCard/InternalAccountInformationCardSkeleton'
import { useInternalAccount } from 'modules/bank/hooks/account/useInternalAccount'
import { InternalAccountInformationCardError } from './InternalAccountInformationCardError/InternalAccountInformationCardError'

export const InternalAccountInformationCardContainer = () => {
  const {
    data: internalAccount,
    isLoading,
    isError,
    errorRefetch,
  } = useInternalAccount()

  if (isError) {
    return <InternalAccountInformationCardError onReload={errorRefetch} />
  }

  if (isLoading || !internalAccount) {
    return <InternalAccountInformationCardSkeleton />
  }

  return <InternalAccountInformationCard account={internalAccount} />
}
