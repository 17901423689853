import { PaginatedList, ProviderApi } from '@blank/api'
import { apiClient } from 'modules/common/http/apiClient'
import { getAuthorizationHeader } from 'modules/common/lib/api/getAuthorizationHeader'

export interface FetchProvidersParams {
  filter: 'ManagementToolsOnboarding' | null
}

interface Props {
  accessToken: string
  fetchParams?: FetchProvidersParams
}

export const fetchProviders = async ({ accessToken, fetchParams }: Props) => {
  const apiUrl = `/v1/bank/accounts/providers`

  const { data } = await apiClient.get<PaginatedList<ProviderApi>>(apiUrl, {
    headers: getAuthorizationHeader(accessToken),
    params: fetchParams,
  })
  return data.data
}
