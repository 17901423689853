import { ComponentProps } from 'react'
import { VerticalProgressStep } from '../components/VerticalProgressStepper/VerticalProgressStep/VerticalProgressStep'
import { EnumVerticalStepStatus } from '../components/VerticalProgressStepper/VerticalProgressStep/VerticalProgressStep.theme'

const getStepStatus = (stepIndex: number, currentStepIndex: number) => {
  if (stepIndex < currentStepIndex) {
    return EnumVerticalStepStatus.COMPLETED
  }
  if (stepIndex === currentStepIndex) {
    return EnumVerticalStepStatus.ONGOING
  }
  return EnumVerticalStepStatus.UPCOMING
}

export const useVerticalProgressStepStatus = (currentStepIndex: number) => ({
  registerStepStatus: (
    stepIndex: number
  ): Pick<ComponentProps<typeof VerticalProgressStep>, 'status'> => ({
    status: getStepStatus(stepIndex, currentStepIndex),
  }),
})
