import { BalanceAmount } from 'modules/bank/components/account/BalanceAmount/BalanceAmount'
import { ErrorReloadSmall } from 'modules/common/components/ErrorReloadSmall/ErrorReloadSmall'
import { RoundedElevatedCard } from 'modules/common/components/RoundedElevatedCard/RoundedElevatedCard'
import { IconInternalAccount } from 'modules/common/components/_icons/IconInternalAccount/IconInternalAccount'
import { useTranslation } from 'modules/common/hooks/useTranslation'

interface Props {
  onReload: () => void
}

export const InternalAccountInformationCardError = ({ onReload }: Props) => {
  const { t } = useTranslation('bank-components')

  return (
    <RoundedElevatedCard>
      <h3 className="mb-4 flex gap-x-2">
        <IconInternalAccount />
        {t('internalAccountInformationCard.title')}
      </h3>
      <div className="my-auto flex flex-col items-center gap-y-2">
        <BalanceAmount amount={undefined} />
        <ErrorReloadSmall
          className="h-auto gap-y-2"
          description={t('internalAccountInformationCard.error')}
          withIcon={false}
          withTitle={false}
          onReload={onReload}
        />
      </div>
    </RoundedElevatedCard>
  )
}
