import { DocumentListCard } from 'modules/invoicing/components/documents/DocumentListCard/DocumentListCard'
import { DocumentListCardError } from 'modules/invoicing/components/documents/DocumentListCard/DocumentListCardError'
import { DocumentListCardSkeleton } from 'modules/invoicing/components/documents/DocumentListCard/DocumentListCardSkeleton'
import { useDocumentsListQuery } from 'modules/invoicing/queries/useDocumentsListQuery'
import { ProductTourCard } from '../ProductTourCard/ProductTourCard'
import { useInvoicingCardProps } from './useInvoicingCardProps'

export const InvoicingContainer = () => {
  const invoicingCardProps = useInvoicingCardProps()

  const {
    isLoading,
    isSuccess,
    isError,
    refetch: errorRefetch,
    data: documentList = [],
  } = useDocumentsListQuery()

  return (
    <>
      {isLoading && <DocumentListCardSkeleton />}
      {isError && <DocumentListCardError onReload={errorRefetch} />}
      {isSuccess && documentList?.length === 0 && (
        <ProductTourCard {...invoicingCardProps} />
      )}
      {isSuccess && documentList?.length > 0 && (
        <DocumentListCard documents={documentList} />
      )}
    </>
  )
}
