import { UnpaidRecordsBannerMessage } from 'modules/billing/components/UnpaidRecordsBannerMessage/UnpaidRecordsBannerMessage'
import { useUnpaidRecordsBanner } from 'modules/billing/components/UnpaidRecordsBannerMessage/useUnpaidRecordsBanner'
import { SponsorshipBannerMessage } from 'modules/settings/components/sponsorship/SponsorshipBannerMessage/SponsorshipBannerMessage'
import { useSponsorshipBanner } from 'modules/settings/components/sponsorship/SponsorshipBannerMessage/useSponsorshipBanner'
import { TDAEDiscoveryBannerMessage } from '../TDAEDiscoveryBannerMessage/TDAEDiscoveryBannerMessage'
import { useTDAEDiscoveryBanner } from '../TDAEDiscoveryBannerMessage/useTDAEDiscoveryBanner'

export const HomePageBanners = () => {
  const {
    isBannerToBeDisplayed: isUnpaidRecordsBannerToBeDisplayed,
    balanceOwed,
  } = useUnpaidRecordsBanner()

  const {
    isBannerToBeDisplayed: isTDAEDiscoveryBannerToBeDisplayed,
    hideBanner: hideTDAEDiscoveryBanner,
  } = useTDAEDiscoveryBanner()

  const {
    isBannerToBeDisplayed: isSponsorshipBannerToBeDisplayed,
    hideBanner: hideSponsorshipBanner,
  } = useSponsorshipBanner()

  if (isUnpaidRecordsBannerToBeDisplayed) {
    return <UnpaidRecordsBannerMessage balanceOwed={balanceOwed} />
  } else if (isTDAEDiscoveryBannerToBeDisplayed) {
    return <TDAEDiscoveryBannerMessage onClose={hideTDAEDiscoveryBanner} />
  } else if (isSponsorshipBannerToBeDisplayed) {
    return <SponsorshipBannerMessage onClose={hideSponsorshipBanner} />
  }

  return null
}
