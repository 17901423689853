import { ErrorReloadSmall } from 'modules/common/components/ErrorReloadSmall/ErrorReloadSmall'
import { RoundedElevatedCard } from 'modules/common/components/RoundedElevatedCard/RoundedElevatedCard'
import { useTranslation } from 'modules/common/hooks/useTranslation'

interface Props {
  title: string
  titleIcon?: JSX.Element
  onReload: () => void
}

export const InternalAccountCardError = ({ title, onReload }: Props) => {
  const { t } = useTranslation('home-components')

  return (
    <RoundedElevatedCard>
      <h3 className="mb-4">{title}</h3>
      <ErrorReloadSmall
        className="gap-y-2 whitespace-pre-line p-6"
        description={t('internalAccountCardError.description')}
        withIcon={false}
        withTitle={false}
        onReload={onReload}
      />
    </RoundedElevatedCard>
  )
}
