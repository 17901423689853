import { EnumAccountSource } from '@blank/api'
import { BankBadge } from 'modules/bank/components/account/BankBadge/BankBadge'
import { IconExpandMore } from 'modules/common/components/_icons/icons'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { DropdownMenu } from 'modules/common/components/_ui/DropdownMenu/DropdownMenu'
import { DropdownMenuItem } from 'modules/common/components/_ui/DropdownMenu/DropdownMenuItem'
import { useDropdownMenu } from 'modules/common/hooks/useDropdownMenu'
import { useMemo } from 'react'

export interface DropdownMenuAccount {
  bankName: string
  accountName: string
  id: string
  externalAccountIconUrl?: string
  accountSource: EnumAccountSource
}

interface Props {
  dropdownMenuAccounts: DropdownMenuAccount[]
  selectedAccountId: string
  onAccountSelected: (accountId: string) => void
}

export const AccountSelectionDropdown = ({
  dropdownMenuAccounts,
  selectedAccountId,
  onAccountSelected,
}: Props) => {
  const {
    registerDropdownButton,
    registerDropdownMenu,
    registerDropdownMenuItem,
  } = useDropdownMenu()

  const selectedBankName = useMemo(
    () =>
      dropdownMenuAccounts.find(({ id }) => id === selectedAccountId)?.bankName,
    [dropdownMenuAccounts, selectedAccountId]
  )

  return (
    <>
      <Button
        variant="tertiary"
        color="default"
        {...registerDropdownButton()}
        className="rounded-lg border border-info-100 p-2"
      >
        {selectedBankName}
        <IconExpandMore className="h-6 w-6 shrink-0" />
      </Button>
      <DropdownMenu
        {...registerDropdownMenu()}
        className="max-h-[240px] w-96 overflow-y-auto"
      >
        {dropdownMenuAccounts.map(
          ({
            id,
            externalAccountIconUrl,
            accountName,
            bankName,
            accountSource,
          }) => (
            <DropdownMenuItem
              key={id}
              {...registerDropdownMenuItem({
                onClick: () => {
                  onAccountSelected(id)
                },
              })}
              className="hover:bg-info-90 active:bg-info-100"
            >
              <BankBadge
                logoUrl={externalAccountIconUrl}
                accountSource={accountSource}
              />
              <div className="flex flex-col items-start text-base">
                <p>{bankName}</p>
                <p className="font-default font-normal text-info-400">
                  {accountName}
                </p>
              </div>
            </DropdownMenuItem>
          )
        )}
      </DropdownMenu>
    </>
  )
}
