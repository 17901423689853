import { mergeClasses } from '@blank/utilities'
import { PropsWithChildren } from 'react'
import { useTheme } from './Tag.theme'

export const TAG_VARIANTS = ['plain', 'outlined'] as const

export const TAG_COLORS = [
  'gray',
  'primary',
  'success',
  'warning',
  'danger',
  'white',
] as const

export type TagColor = (typeof TAG_COLORS)[number]
export type TagVariant = (typeof TAG_VARIANTS)[number]

export interface TagProps {
  variant: TagVariant
  color: TagColor
  className?: string
  icon?: React.ReactNode
  iconPosition?: 'left' | 'right'
}

export const Tag = ({
  variant,
  color,
  children,
  className,
  icon,
  iconPosition,
}: PropsWithChildren<TagProps>) => {
  const { themeClassName } = useTheme({
    color,
    variant,
  })

  return (
    <span
      className={mergeClasses(
        'inline-flex items-center justify-center gap-x-2 rounded-full px-3 py-2',
        { 'border-2': variant === 'outlined' },
        themeClassName,
        className
      )}
    >
      {iconPosition === 'left' && icon}
      <span className="font-semibold leading-5 first-letter:capitalize">
        {children}
      </span>
      {iconPosition === 'right' && icon}
    </span>
  )
}
