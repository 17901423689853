import { DocumentApi } from '@blank/api'
import { AmountWithTax } from 'modules/common/components/AmountWithTax/AmountWithTax'
import { Link } from 'modules/common/components/Link/Link'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { EnumRoutes } from 'modules/common/routes'
import { getDocumentRedirectionPath } from 'modules/invoicing/lib/document'
import { formatDocumentNumber } from 'modules/invoicing/lib/format/documentNumber'
import { decimalPrice } from 'modules/invoicing/lib/format/price'

interface Props {
  documentList: DocumentApi[]
}

export const DocumentList = ({ documentList }: Props) => {
  const { t } = useTranslation(['invoicing-components', 'common'])

  return (
    <div className="flex flex-col items-center">
      <div className="w-full px-4 pt-3">
        {/* Link anchor is not compatible with <table /> tag */}
        <div className="table w-full border-collapse divide-y divide-info-100">
          {documentList.slice(0, 5).map((document) => {
            const { id, status, totalWithoutVAT, documentNumber, title } =
              document
            return (
              <Link
                key={id}
                href={getDocumentRedirectionPath(document)}
                className="table-row w-full cursor-pointer hover:bg-info-90 active:bg-info-100"
              >
                <div className="table-cell w-1/2 max-w-0 truncate py-1 align-middle">
                  <p className="truncate font-bold">
                    {formatDocumentNumber(documentNumber) || '-'}
                  </p>
                  <p className="truncate text-info-400">{title || '-'}</p>
                </div>
                <div className="table-cell w-1/4 min-w-[76px] py-1 align-middle font-bold text-info-400">
                  {t(`documentStatus.label.${status}`)}
                </div>
                <div className="table-cell w-1/4 min-w-[120px] py-1 text-right align-middle">
                  {totalWithoutVAT ? (
                    <AmountWithTax
                      amount={decimalPrice(totalWithoutVAT)}
                      includingTax={false}
                    />
                  ) : (
                    '-'
                  )}
                </div>
              </Link>
            )
          })}
        </div>
      </div>
      {documentList.length > 5 && (
        <Button
          href={EnumRoutes.INVOICING_DOCUMENTS}
          variant="tertiary"
          color="default"
        >
          {t('common:seeMore')}
        </Button>
      )}
    </div>
  )
}
