import { useBanner } from 'modules/common/hooks/useBanner'
import { EnumBannerKey } from 'modules/common/types/banner'
import { useCorporateSponsorship } from 'modules/corporate/hooks/useCorporateSponsorship'

export const useSponsorshipBanner = () => {
  const { isEligibleForSponsorship } = useCorporateSponsorship()

  return useBanner({
    bannerKey: EnumBannerKey.SPONSORSHIP,
    displayCondition: isEligibleForSponsorship,
  })
}
