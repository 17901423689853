import { Link } from 'modules/common/components/Link/Link'
import { IconGift } from 'modules/common/components/_icons/icons'
import { BannerMessage } from 'modules/common/components/_ui/BannerMessage/BannerMessage'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { EnumRoutes } from 'modules/common/routes'
import { useCorporateSponsorship } from 'modules/corporate/hooks/useCorporateSponsorship'
import { Trans } from 'next-i18next'

interface Props {
  onClose?: () => void
}

export const SponsorshipBannerMessage = ({ onClose }: Props) => {
  const { t } = useTranslation('settings-components')

  const { activeVoucher } = useCorporateSponsorship()

  if (activeVoucher) {
    const { referrerReward, refereeReward } = activeVoucher
    return (
      <BannerMessage icon={<IconGift />} type="important" onClose={onClose}>
        <Trans
          components={{
            linkComponent: (
              <Link
                href={EnumRoutes.SETTINGS_SPONSORSHIP}
                className="font-bold underline"
              />
            ),
          }}
        >
          {t('sponsorshipBannerMessage', { referrerReward, refereeReward })}
        </Trans>
      </BannerMessage>
    )
  }

  return null
}
