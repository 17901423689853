import { Skeleton } from 'modules/common/components/_ui/Skeletons/Skeleton/Skeleton'

export const TransactionListSkeleton = () => {
  const itemKeys = Array.from(Array(5).keys())

  return (
    <div className="mx-4 my-3">
      <table className="w-full">
        <tbody className="divide-y divide-info-100">
          {itemKeys.map((key) => {
            return (
              <tr key={key} className="w-full">
                <td className="w-1/2 py-3 font-bold">
                  <div className="flex space-x-2">
                    <Skeleton className="h-6 w-6 shrink-0 rounded-lg" />
                    <Skeleton className="h-6 w-full max-w-[160px]" />
                  </div>
                </td>
                <td className="w-1/4 py-3">
                  <Skeleton className="h-6 w-20" />
                </td>
                <td className="w-1/4 py-3">
                  <span className="flex justify-end">
                    <Skeleton className="h-6 w-24" />
                  </span>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
