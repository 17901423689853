import { TransactionApi } from '@blank/api'
import { ErrorReloadSmall } from 'modules/common/components/ErrorReloadSmall/ErrorReloadSmall'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { AccountBlockedInvest } from '../AccountBlockedInvest/AccountBlockedInvest'
import { TransactionList } from './TransactionList/TransactionList'
import { TransactionListSkeleton } from './TransactionList/TransactionListSkeleton'

interface Props {
  isTransactionsLoading?: boolean
  isTransactionsSuccess?: boolean
  isTransactionsError?: boolean
  isBlockedInvest?: boolean
  transactionList?: TransactionApi[]
  selectedAccountId: string
  refetchTransactions: () => void
}

export const TransactionListContainer = ({
  isTransactionsLoading,
  selectedAccountId,
  isTransactionsError,
  isTransactionsSuccess,
  isBlockedInvest,
  transactionList,
  refetchTransactions,
}: Props) => {
  const { t } = useTranslation('bank-components')

  if (isBlockedInvest) {
    return <AccountBlockedInvest />
  }

  if (isTransactionsLoading) {
    return <TransactionListSkeleton />
  }

  if (isTransactionsError) {
    return (
      <ErrorReloadSmall
        className="whitespace-pre-line px-6 py-16"
        description={t('transactionListCard.error')}
        onReload={refetchTransactions}
        withTitle={false}
        withIcon={false}
      />
    )
  }

  if (isTransactionsSuccess && transactionList) {
    return (
      <TransactionList
        transactionList={transactionList}
        accountId={selectedAccountId}
      />
    )
  }

  return null
}
