import { EnumTransferMethod } from '@blank/api'
import { TransferMethodFormModalContent } from 'modules/bank/components/transfer/TransferMethodFormModalContent/TransferMethodFormModalContent'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useFormModal } from 'modules/common/modals/useFormModal/useFormModal'
import { EnumRoutes } from 'modules/common/routes'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'

export interface TransferMethodFormData {
  transferMethod: EnumTransferMethod
}

export const useTransferMethodFormModal = (beneficiaryId?: string) => {
  const { i18n, t } = useTranslation(['bank-components', 'common'])

  const router = useRouter()

  const form = useForm<TransferMethodFormData>({
    defaultValues: {
      transferMethod: EnumTransferMethod.DIRECT,
    },
  })

  const { register, watch } = form

  const selectedTransferMethod = watch('transferMethod')

  return useFormModal({
    width: 'medium',
    i18n,
    form,
    content: <TransferMethodFormModalContent register={register} />,
    headerTitle: t('transferMethodModal.title'),
    secondaryButtonText: t('common:cancel'),
    submitButtonText: t('common:next'),
    overlayTransitionType: 'transition-none',
    onSubmit: () =>
      router.push({
        pathname: EnumRoutes.ACCOUNT_NEW_TRANSFER,
        query: {
          ...(beneficiaryId && { beneficiaryId }),
          ...(selectedTransferMethod && { method: selectedTransferMethod }),
        },
      }),
  })
}
