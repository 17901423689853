import { TransactionInformationApi } from '@blank/api'
import dayjs from 'dayjs'
import { extractAmount } from 'modules/common/lib/helpers/amount'

export const formatTransactionAmount = (value: number, currency?: string) => {
  const { symbol, decimal, integer } = extractAmount(value, currency)

  const isNegative = value < 0

  return `${isNegative ? '-' : '+'} ${
    isNegative ? integer.replace('-', '') : integer
  },${decimal} ${symbol}`
}

export const formatInlineVATRate = (
  vatArray?: TransactionInformationApi['vat']
) => {
  return vatArray?.map(({ rate }) => (rate ? `${rate}%` : '')).join(', ')
}

export const formatExportTransactionsDate = (rawDate: string) =>
  dayjs(rawDate).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
