import { EnumAccountLimitResourceId } from '@blank/api'
import { useInternalAccount } from 'modules/bank/hooks/account/useInternalAccount'
import { useAccountLimitsQuery } from 'modules/bank/queries/useAccountLimitsQuery.queries'
import { useCallback } from 'react'

export const useInternalAccountTransferLimitsQueries = () => {
  const {
    data: internalAccount,
    isLoading: isInternalAccountLoading,
    isError: isInternalAccountError,
    isSuccess: isInternalAccountSuccess,
    errorRefetch: refetchInternalAccount,
  } = useInternalAccount()

  const {
    data: accountLimits,
    isLoading: isAccountLimitsLoading,
    isError: isAccountLimitsError,
    isSuccess: isAccountLimitsSuccess,
    refetch: refetchAccountLimits,
  } = useAccountLimitsQuery(internalAccount?.id)

  const errorRefetch = useCallback(() => {
    if (isInternalAccountError) {
      refetchInternalAccount()
    }
    if (isAccountLimitsError) {
      refetchAccountLimits()
    }
  }, [
    isInternalAccountError,
    isAccountLimitsError,
    refetchInternalAccount,
    refetchAccountLimits,
  ])

  return {
    data: {
      transferLimits: {
        dailyLimit: accountLimits?.find(
          ({ resourceId }) => resourceId === EnumAccountLimitResourceId.DAILY
        )?.parametersRule,
        monthlyLimit: accountLimits?.find(
          ({ resourceId }) => resourceId === EnumAccountLimitResourceId.MONTHLY
        )?.parametersRule,
      },
      internalAccount,
    },
    isLoading: isInternalAccountLoading || isAccountLimitsLoading,
    isError: isInternalAccountError || isAccountLimitsError,
    isSuccess: isInternalAccountSuccess && isAccountLimitsSuccess,
    errorRefetch,
  }
}
