import { EnumBrand } from '@blank/api'
import { buildTimeBrand } from 'config'
import { IconBlank, IconCA, IconLCL } from '../icons'

interface Props {
  className?: string
}

export const IconInternalAccount = ({ className }: Props) => {
  switch (buildTimeBrand) {
    case EnumBrand.BLANK: {
      return <IconBlank className={className} />
    }
    case EnumBrand.CA: {
      return <IconCA className={className} />
    }
    case EnumBrand.LCL: {
      return <IconLCL className={className} />
    }
  }
}
